.goodsCard {
    width: 250px;
    direction: ltr;
    z-index: 1;
    position: relative;
    cursor: pointer;
}

.goodsLogo {
    height: 180px;
    object-fit: contain;
}

.zoomed-image {
    position: absolute;
    width: 100%;
    height: 180px;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.magnifier {
    width: 70px;
    height: 70px;
    position: relative;
    fill: #bb2d3b;
}

.cardText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cardName {
    color: #bb2d3b !important;
}

.cardPrice {
    font-weight: bold;
}

.blur {
    /*animation: backdrop-filter-animation 0.2s ease-in;*/
    backdrop-filter: blur(4px);
    position: absolute;
    width: 100%;
    height: 195px;
    top: 0;
    left: 0;
    z-index: 5;
}


/*@keyframes backdrop-filter-animation {*/
/*    0% {*/
/*        backdrop-filter: blur(0px);*/
/*    }*/
/*    50% {*/
/*         backdrop-filter: blur(4px);*/
/*    }*/
/*    100% {*/
/*        backdrop-filter: blur(4px);*/
/*    }*/
/*}*/

.goodsCard .goodsBadge {
    position: absolute;
    bottom: 18px;
    right: 20px;
    z-index: 10;
    font-size: 1em;
}