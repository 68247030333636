.modal-backdrop {
    background-color: transparent !important;
}

.modal-dialog {
    width: 60% !important;
    max-width: none !important;
}

.modal-dialog-centered {
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.modal-content {
    min-height: 60vh;
    max-height: 80vh;
    min-width: 800px;
    background-color: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(4px);
}

.modal-body {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 40px 50px !important;
}

.top_part {
    flex: 0 0 55%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    max-height: 400px;
}

.modalImage {
    width: 45%;
    min-width: 200px;
    object-fit: contain;
    border-radius: 10px;
}

.modalName {
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 2em;
}

.modalNameExactly {
    max-width: 100%;
    padding: 0 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.modalNameDiameters {
    font-size: 0.6em;
    color: #bb2d3b;
}

.closeButton {
    width: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.closeButtonImage {
    width: 30px;
    height: 30px;
}

.closeButton:hover {
    cursor: pointer;
}

.bottom_part {
    flex-grow: 1;
    margin-top: 30px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    font-weight: bold;
    overflow: auto;
}

.description_description {
    margin-bottom: 20px;
    font-size: 2em;
}

.modalDescriptionExactly {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;
    word-wrap: break-word;
}

.nonFinalPrice {
    font-size: 0.5em;
    color: black;
}

.modelNamePrice {
    margin-top: 30px;
    color: #bb2d3b;
}