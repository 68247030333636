.carouselImage {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    height: 500px;
}

.carouselImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}