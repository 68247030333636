.header {
    z-index: 10;
    width: 100%;
    height: 80px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 !important;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

.logo {
    width: 45%;
    height: 100%;
    min-width: 250px;
    cursor: pointer;
}

.el {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.header_left {
    justify-content: end;
    font-size: 1.5em;
    font-weight: bold;
}

.header_logo {
    align-self: center;
}

.header_right {
    justify-content: space-around;
    min-width: 600px;
}

.header_left .delimiter {
    width: 30%;
}

.clock {
    width: 35px;
    height: 35px;
}

.smartphone {
    width: 50px;
    height: 50px;
}

.map {
    width: 40px;
    height: 40px;
}

.schedule, .callUs, .location {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.schedule .delimiter {
    width: 10px;
}

.callUs .delimiter {
    width: 10px;
}

.location .delimiter {
    width: 10px;
}

.header_right {
    font-size: 0.8em;
    font-weight: bold;
}

.callUs a {
    text-decoration: none;
    color: black;
}

.callUs a:hover {
    color: #bb2d3d;
}

.header_button {
    cursor: pointer;
}

.header_button:hover {
    color: #bb2d3d;
}

.location {
    cursor: pointer;
}