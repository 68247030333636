.layoutStore {
    position: relative;
    padding: 25px 75px 0 75px;
    scroll-snap-align: start;
}

.block_name {
    display: flex;
    justify-content: left;
    align-items: center;
}

.name {
    font-weight: bold;
    font-size: 2em;
}

.cart {
    height: 45px;
    width: 45px;
}

.delimiter {
    width: 10px;
}

.goods_place {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
}

.goods_place_left {
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 20px;
    border-right: 1px black solid;
}

.goods_place_right {
    flex-grow: 0;
    flex-shrink: 0;
    width: 400px;
    padding-left: 20px;
}

.nav-item button {
    text-transform: uppercase;
    font-weight: bold;
}

.tabs {
    padding: 10px 0;
}

.nav-link.active {
    color: #bb2d3d !important;
}

.nav-link {
    color: black !important;
}

.nav-link:hover {
    background-color: rgba(187, 45, 61, 0.1);
}

.reset-button {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.reset-button button {
    color: #bb2d3b;
    border-color: #bb2d3b;
}

.reset-button button:hover {
    background-color: rgba(187, 45, 59, 0.1);
}

#store {
    scroll-margin-top: 80px;
}