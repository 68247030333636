.smartPhone {
    height: 45px;
    width: 45px;
}

#contacts {
    scroll-margin-top: 78px;
}

.delimiter {
    width: 10px;
}

.layoutContacts {
    position: relative;
    padding: 25px 75px;
    scroll-snap-align: start;
}

.contacts_layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 30vh;
}

.contacts_layout_left {
    min-width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contacts_layout_right {
    min-width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contacts_layout_left_header {
    font-weight: bold;
    font-size: 2.6em;
    margin-bottom: 30px;
}

.contacts_layout_left_info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 2.6em;
}

.contacts_layout_left_info_smartPhone {
    height: 60px;
    width: 60px;
    fill: #bb2d3b;
}

.contacts_layout_right_header {
    font-weight: bold;
    font-size: 2.6em;
    margin-bottom: 30px;
}

.contacts_layout_right_info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 2.6em;
}

.contacts_layout_right_info_mailLogo {
    height: 60px;
    width: 60px;
}

.contacts_layout_right_info_mail {
    cursor: pointer;
}

.contacts_layout_left_info_phone {
    cursor: pointer;
}
