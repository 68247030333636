@media (max-width: 1300px) {
    html {
        zoom: 0.7;
    }
}

@media (min-width: 1300px) and (max-width: 1650px) {
    html {
        zoom: 0.8;
    }
}

html {
    scroll-snap-type: y proximity;
}

body {
    margin: 0;
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}