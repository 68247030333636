input[type="checkbox"] {
    display: none;
}

input[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: 35px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    line-height: 20px;
}

input[type="checkbox"] + label:last-child {
    margin-bottom: 0;
}

input[type="checkbox"] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #bb2d3b;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .6;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
}

input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.filterList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
}

.accordion-button:not(.collapsed) {
    background-color: #fff !important;
    color: #bb2d3b !important;
}