#map {
    scroll-margin-top: 80px;
    scroll-snap-align: start;
}

.delimiter {
    width: 10px;
}

.layoutMap {
    position: relative;
    padding: 25px 75px 10px 75px;
}

.mapLogo {
    height: 55px;
    width: 55px;
}

.mapAddress {
    padding: 0 0 20px 50px;
    font-size: 1.5em;
    font-weight: bold;
    color: #bb2d3b;
}