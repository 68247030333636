.main {
    background-color: rgba(0, 0, 0, 0.09);
    width: 100%;
    min-height: 100vh;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #555 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    border: 3px solid #ffffff;
}