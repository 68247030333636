.tab {
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 12px;
    overflow: auto;
    direction: rtl;
    scrollbar-width: auto;
    scrollbar-color: #e16b6b #ffffff;
    height: 70vh;
}

@media (max-width: 1300px) {
    .tab {
        height: 100vh;
    }
}

@media (min-width: 1300px) and (max-width: 1650px) {
    .tab {
        height: 85vh;
    }
}

@media (min-width: 1650px) and (max-width: 1900px) {
    .tab {
        height: 75vh;
    }
}

/* Chrome, Edge, and Safari */
.tab::-webkit-scrollbar {
    width: 16px;
}

.tab::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 20px;
}

.tab::-webkit-scrollbar-thumb {
    background-color: #e16b6b;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.notingFound {
    direction: ltr;
    width: 100%;
    text-align: center;
    font-weight: bold;
}